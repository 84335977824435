<template>
    <div :style='{"font-size":"7pt", "line-height": "normal"}'>
        <div :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400", "background-color": "#D3D3D3"}}'>
            Oxigenoterapia
        </div>
        <div>
            {{datos?datos.oxigenoterapia.split(',').join(', '):''}}
        </div>

        <div :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400", "background-color": "#D3D3D3"}}' v-if="datos && datos.descripcionVm">
            Descripción de ventilación mecánica
        </div>
        <div v-if="datos && datos.descripcionVm">
            <div :style='{"vertical-align":"top", "white-space": "pre-wrap"}'>{{datos?datos.descripcionVm:''}}</div>
        </div>

    </div>
</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],

}
</script>

<style>

</style>